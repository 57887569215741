/*------------------
    Filter
-------------------*/

$modal-filter-color-1:              rgba($alpha, .7);
$modal-filter-color-2:              rgba($alpha, .7);

$modal-padding:                     $spacer-75 $spacer-10;
$modal-padding-desk:                $spacer-40 $spacer-75;


/*------------------
    Container
-------------------*/

$modal-border-radius:               0;
$modal-background-color:            $white;
$modal-box-shadow:                  0 10px 60px rgba($black, .2);
$modal-max-width:                   600px;
$modal-max-width-small:             400px;
$modal-max-width-large:             1000px;


/*--------- Header ----------*/
$modal-padding-header:              $spacer-15 $spacer-30;
$modal-background-color-header:     $gray-100;

$modal-color-header:                $gray-900;
$modal-font-family-header:          $sans-serif;
$modal-font-size-header:            $fs-20;
$modal-font-weight-header:          $fw-semi-bold;
$modal-letter-spacing-header:       0;
$modal-line-height-header:          $lh18;


/*--------- Content ----------*/
$modal-padding-content:             $spacer-30;


/*--------- Footer ----------*/
$modal-padding-footer:              $spacer-15 $spacer-30;
$modal-background-color-footer:     $gray-100;

/*--------- Close ----------*/
$modal-close-background:            $alpha;
$modal-close-hover-background:      $beta;

/*------------------
    Transition
-------------------*/

$modal-transition-modal:            .3s;
$modal-transition-container:        .3s;
