@import 'variables';
@import 'mixins';

/*******************************
        Main
*******************************/

.modal {
  display: none;

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  padding: $modal-padding;

  transition: $modal-transition-modal;
  transition-delay: $modal-transition-container;

  background: linear-gradient($modal-filter-color-1, $modal-filter-color-2);

  opacity: 0;
  visibility: hidden;

  z-index: 10;

  @include scrollbar-y;

  &.is-open {
    transition-delay: 0s;
    opacity: 1;
    visibility: visible;

    .modal-container {
      transform: none;
      transition-delay: $modal-transition-modal;
      opacity: 1;

      @include cubicOut;
    }
  }

  &.is-small {
    .modal-container {
      max-width: $modal-max-width-small;
    }
  }

  &.is-large {
    .modal-container {
      max-width: $modal-max-width-large;
    }
  }

  &.is-full {
    .modal-container {
      max-width: none;
    }
  }
}

.modal-container {
  position: relative;

  width: 100%;
  max-width: $modal-max-width;

  margin: auto;

  transform: scale(.9) translateY(-30px);

  transition: $modal-transition-container;
  @include cubicIn;

  border-radius: $modal-border-radius;

  background-color: $modal-background-color;

  box-shadow: $modal-box-shadow;
  opacity: 0;
}

.modal-close {
  position: absolute;

  right: 0;
  bottom: 100%;

  transform: translateY(-$spacer-10);

  background-color: $modal-close-background;

  @include hover {
    background-color: $modal-close-hover-background;
  }
}

.modal-header {
  padding: $modal-padding-header;
  background-color: $modal-background-color-header;

  @include border-top-radius($modal-border-radius);
}

.modal-title {
  color: $modal-color-header;
  font-family: $modal-font-family-header;
  font-size: $modal-font-size-header;
  font-weight: $modal-font-weight-header;
  letter-spacing: $modal-letter-spacing-header;
  line-height: $modal-line-height-header;
}

.modal-content {
  padding: $modal-padding-content;
}

.modal-footer {
  padding: $modal-padding-footer;
  background-color: $modal-background-color-footer;

  @include border-bottom-radius($modal-border-radius);
}

/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {

}

@include breakpoint('tab') {

}

@include breakpoint('desk') {
  .modal {
    padding: $modal-padding-desk;
  }

  .modal-close {
    top: 25px;
    right: auto;
    bottom: auto;
    left: 100%;

    transform: translate(-50%, 0);
  }
}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {

}
