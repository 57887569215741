// BUGS -> https://github.com/philipwalton/flexbugs
// GUIDE -> https://css-tricks.com/snippets/css/a-guide-to-flexbox/

// FIX IE Essais à faire pour généralisation
// .flex-fix { flex: 1 0 auto; }


// CONTAINER
.flex { display: flex; }

.flex-row {
	display: flex;
	flex-direction: row;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.flex-center {
    align-items: center;
    justify-content: center;
}


//ALIGN ITEMS
.items-center { align-items: center; }

.items-start { align-items: flex-start; }

.items-end { align-items: flex-end; }

.items-stretch { align-items: stretch; }

.items-baseline { align-items: baseline; }


//ALIGN CONTENT
.content-center { align-content: center; }

.content-start { align-content: flex-start; }

.content-end { align-content: flex-end; }

.content-stretch { align-content: stretch; }

.content-baseline { align-content: baseline; }


//JUSTIFY CONTENT
.is-justified-center { justify-content: center; }

.is-justified-start { justify-content: flex-start; }

.is-justified-end { justify-content: flex-end; }

.is-justified-between { justify-content: space-between; }

.is-justified-around { justify-content: space-around; }


//FLEX WRAP
.flex-wrap { flex-wrap: wrap; }

.flex-nowrap { flex-wrap: nowrap; }


//ALIGN SELF
.self-center { align-self: center; }

.self-start { align-self: flex-start; }

.self-end { align-self: flex-end; }

.self-stretch { align-self: stretch; }

.self-baseline { align-self: baseline; }


//ORDER
.order1 { order: 1; }

.order0 { order: 0; }

.order-1 { order: -1; }


//FLEX
.flex1 { flex: 1; }


//FLEX GROW
// .grow1 { flex-grow: 1; }

// .grow0 { flex-grow: 0; }


//FLEX SHRINK
// .shrink1 { flex-shrink: 1; }

// .shrink0 { flex-shrink: 0; }
