/*------------------
    Default
-------------------*/

$grid-columns:          12;
$col-width:             100 / $grid-columns * 1%;

$gutter-width:          $spacer-10;

$container-max-width:   1200px;

$enable-reverse:        true;
$enable-vertical-align: true;

$enable-ordering:       true;
$enable-offset:         true;
