* {
  margin: 0;
  padding: 0;

  font-size: $fs-base;
  font-weight: $fw-base;

  box-sizing: border-box;
}

body {
  height: 100%;
}

html {
  background-color: $body-bg;
  color: $text-color;
  font-family: $font-family-base;

  -ms-overflow-style: scrollbar;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba($black, .05);
  -webkit-overflow-scrolling: touch;

  a {
    color: $link-color;
    text-decoration: none;

    img {
      border: 0;
    }
  }

  img {
    display: block;
    max-width: 100%;
  }

  label {
    user-select: none;
  }

  textarea {
    resize: none;
  }

  address {
    font-style: normal;
  }

  pre {
    padding: 20px;
    border-radius: 5px;
    background-color: $gray-900;
    color: $gray-300;
    overflow: auto;
  }

  select,
  button,
  input,
  textarea {
    font-family: $font-family-base;
    appearance: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  a,
  li,
  input,
  button,
  select,
  textarea {
    outline: none !important;
  }

  button {
    padding: 0;
    border-style: solid;
    cursor: pointer;
  }

  hr {
    margin: 0;

    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: darken($border-color, 10%);
  }
}
