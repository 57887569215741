.page-header {
  .button {
    position: absolute;
    top: $spacer-30;
    right: $spacer-30;

    z-index: 1;
  }
}

.page-content {
  position: relative;
  margin-top: -200px;
  padding-bottom: $spacer-100;

  .leading-text {
    font-size: $fs-40;
  }
}

.chips-list {
  padding-top: $spacer-20;
  border-top: 1px solid $border-color;
  color: $alpha;

  + * {
    margin-top: $spacer-20;
    padding-top: $spacer-20;
    border-top: 1px solid $border-color;
  }

  li {
    @include padding-v($spacer-5);
  }

  [class*='chip-'] {
    width: 14px;
    height: 14px;

    box-shadow: 0 0 10px rgba($black, .3);
  }
}

.svg-wrapper {
  @include margin-v($spacer-20);

  text-align: center;

  svg {
    border-radius: 50%;
    box-shadow: 0 0 20px rgba($black, .3);
  }
}
