@import 'variables';
@import 'mixins';

/*******************************
        Main
*******************************/

.wysiwyg {
  color: $color;
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  letter-spacing: $letter-spacing;
  line-height: $line-height;

  a {
    border-width: $border-width-link;
    border-style: $border-style-link;
    border-color: $border-color-link;

    color: $color-link;
    font-weight: $font-weight-link;

    @include hover {
      border-color: $border-color-link-hover;

      color: $color-link-hover;
    }
  }

  strong {
    font-weight: $font-weight-strong;
  }

  ul {
    list-style: $list-style-ul;
  }

  ol {
    list-style-type: $list-style-type-ol;
  }

  ul,
  ol {
    padding-left: $spacer-15;
  }

  blockquote {
    position: relative;

    padding: $padding-bq;

    color: $color-bq;
    font-family: $font-family-bq;
    font-size: $font-size-bq;
    font-weight: $font-weight-bq;
    letter-spacing: $letter-spacing-bq;
    line-height: $line-height-bq;

    @include before {
      top: 0;
      left: 0;

      width: $border-width-bq;
      height: 100%;

      background: linear-gradient($border-color-bq-1, $border-color-bq-2);
    }
  }

  hr {
    border-color: $border-color-hr;
    @include margin-v($margin-hr);
  }

  h1 {
    color: $color-h1;
    font-family: $font-family-h1;
    font-size: $font-size-h1;
    font-weight: $font-weight-h1;
    letter-spacing: $letter-spacing-h1;
    line-height: $line-height-h1;
  }

  h2 {
    color: $color-h2;
    font-family: $font-family-h2;
    font-size: $font-size-h2;
    font-weight: $font-weight-h2;
    letter-spacing: $letter-spacing-h2;
    line-height: $line-height-h2;
  }

  h3 {
    color: $color-h3;
    font-family: $font-family-h3;
    font-size: $font-size-h3;
    font-weight: $font-weight-h3;
    letter-spacing: $letter-spacing-h3;
    line-height: $line-height-h3;
  }

  h4 {
    color: $color-h4;
    font-family: $font-family-h4;
    font-size: $font-size-h4;
    font-weight: $font-weight-h4;
    letter-spacing: $letter-spacing-h4;
    line-height: $line-height-h4;
  }

  h5 {
    color: $color-h5;
    font-family: $font-family-h5;
    font-size: $font-size-h5;
    font-weight: $font-weight-h5;
    letter-spacing: $letter-spacing-h5;
    line-height: $line-height-h5;
  }

  h6 {
    color: $color-h6;
    font-family: $font-family-h6;
    font-size: $font-size-h6;
    font-weight: $font-weight-h6;
    letter-spacing: $letter-spacing-h6;
    line-height: $line-height-h6;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      margin-top: $spacer;
    }

    + p,
    + ul,
    + ol,
    + img,
    + blockquote {
      margin-top: $spacer-large;
    }
  }

  p,
  ul,
  ol,
  img,
  blockquote {
    + p,
    + ul,
    + ol,
    + img,
    + blockquote {
      margin-top: $spacer;
    }

    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      margin-top: $spacer-xlarge;
    }
  }

  &.is-inverse {
    color: $color-inverse;
  }
}


/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {

}

@include breakpoint('tab') {

}

@include breakpoint('desk') {

}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {

}
