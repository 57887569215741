@import 'variables';
@import 'mixins';

.checkbox {
  display: inline-block;
  position: relative;

  padding-left: 32px;
  @include padding-v(10px);

  @include hover {
    color: $beta;

    span {
      transform: translateY(-50%) scale(1.1);
    }
  }

  span {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;

    width: 22px;
    height: 22px;

    transform: translateY(-50%);

    border: 1px solid $gray-200;
    border-radius: 1px;

    background-color: $white;

    @include InOutBack;

    &,
    &::before {
      transition: .3s;
    }

    @include before {
      top: 50%;
      left: 50%;

      width: 50%;
      height: 22%;

      transform: translate(-50%, -50%) rotate(-45deg) scale(1.5);

      border-width: 0 0 2px 2px;
      border-style: solid;
      border-color: $white;

      opacity: 0;
    }
  }

  input {
    opacity: 0;
  }

  input:checked + span {
    border-color: $alpha;

    background-color: $alpha;

    &::before {
      transform: translate(-50%, -50%) rotate(-45deg);
      transition-delay: .2s;
      opacity: 1;
    }
  }
}
