.card-content.has-tabs {
  padding-bottom: 0 !important;

  .tab-toggles {
    margin-top: $spacer-30;
  }
}

/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {

}

@include breakpoint('tab') {

}

@include breakpoint('desk') {
  .card {
    &.is-large {
      .card-content {
        padding: $spacer-40 $spacer-50 + $spacer-10;

        + .card-content {
          padding: $spacer-40 $spacer-50 + $spacer-10 $spacer-50 + $spacer-10;
        }
      }
    }
  }
}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {

}



