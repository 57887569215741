@mixin triangle-right($width, $height, $color: $white) {
  width: 0;
  height: 0;
  border-width: $height / 2 0 $height / 2 $width;
  border-style: solid;
  border-color: transparent transparent transparent $color;
}

@mixin triangle-bottom($width, $height, $color: $white) {
  width: 0;
  height: 0;
  border-width: $height / 2 0 $height / 2 $width;
  border-style: solid;
  border-color: transparent transparent transparent $color;

  width: 0;
  height: 0;
  border-style: solid;
  border-width: $height $width / 2 0 $width / 2;
  border-color: $color transparent transparent transparent;
}
