.select-container {
  position: relative;

  &::before,
  &::after {
    pointer-events: none;
  }

  @include before {
    top: 1px;
    right: 1px;

    width: 60px;
    height: calc(100% - 2px);

    background: linear-gradient(90deg, rgba($white, 0) 0%, $white 50%);

    @include border-right-radius(50px);
  }

  @include after {
    top: 50%;
    right: 20px;

    transform: translateY(-50%);

    @include triangle-bottom(9px, 5px, $gray-200);
  }
}
