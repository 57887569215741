@import 'variables';
@import 'mixins';

/*******************************
        Main
*******************************/

.title-h1 {
  font-family: $font-family-h1;
  font-size: $fs-h1;
  font-weight: $fw-h1;
  line-height: $lh12;
  text-transform: $text-transform-h1;
}

.title-h2 {
  font-family: $font-family-h2;
  font-size: $fs-h2;
  font-weight: $fw-h2;
  line-height: $lh12;
  text-transform: $text-transform-h2;
}

.title-h3 {
  font-family: $font-family-h3;
  font-size: $fs-h3;
  font-weight: $fw-h3;
  letter-spacing: $ls-05;
  line-height: $lh12;
  text-transform: $text-transform-h3;
}

.title-h4 {
  font-family: $font-family-h4;
  font-size: $fs-h4;
  font-weight: $fw-h4;
  letter-spacing: $ls-05;
  line-height: $lh12;
  text-transform: $text-transform-h4;
}

.title-h5 {
  font-family: $font-family-h5;
  font-size: $fs-h5;
  font-weight: $fw-h5;
  line-height: $lh12;
  text-transform: $text-transform-h5;
}

.title-h6 {
  font-family: $font-family-h6;
  font-size: $fs-h6;
  font-weight: $fw-h6;
  line-height: $lh12;
  text-transform: $text-transform-h6;
}

/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {

}

@include breakpoint('tab') {
  .title-h1 {
    font-size: $fs-h1-tab;
  }

  .title-h2 {
    font-size: $fs-h2-tab;
  }
}

@include breakpoint('desk') {

}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {

}

