@mixin cubicIn { transition-timing-function: cubic-bezier(.550, .055, .675, .190); }
@mixin cubicOut { transition-timing-function: cubic-bezier(.215, .610, .355, 1.000); }
@mixin cubicInOut { transition-timing-function: cubic-bezier(.645, .045, .355, 1.000); }

@mixin quartIn { transition-timing-function: cubic-bezier(.895, .030, .685, .220); }
@mixin quartOut { transition-timing-function: cubic-bezier(.165, .840, .440, 1.000); }
@mixin quartInOut { transition-timing-function: cubic-bezier(.770, .000, .175, 1.000); }

@mixin quintIn { transition-timing-function: cubic-bezier(.755, .050, .855, .060); }
@mixin quintOut { transition-timing-function: cubic-bezier(.230, 1.000, .320, 1.000); }
@mixin quintInOut { transition-timing-function: cubic-bezier(.860, .000, .070, 1.000); }

@mixin InBack { transition-timing-function: cubic-bezier(.600, -.280, .735, .045); }
@mixin OutBack { transition-timing-function: cubic-bezier(.175, .885, .320, 1.275); }
@mixin InOutBack { transition-timing-function: cubic-bezier(.680, -.550, .265, 1.550); }
