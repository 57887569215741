.section-error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - #{$header-height-tab});

  text-align: center;

  > div * + * {
    margin-top: $spacer-30;
  }
}
