@mixin icon($color) {
  transition: .5s;
  @include InOutBack;

  span::before,
  span::after,
  &::before,
  &::after {
    transition: .5s;
    @include InOutBack;
    border-color: $color;
    background-color: $color;
  }
}
