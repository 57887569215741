@mixin card($background) {
  background-color: $background;

  .card-content {
    color: set-color-from-bg($background, $card-color, $card-color-inverse);

    .card-title {
      color: set-color-from-bg($background, $card-color-title, $card-color-title-inverse);
    }

    .card-subtitle {
      color: set-color-from-bg($background, $card-color-subtitle, $card-color-subtitle-inverse);
    }
  }
}
