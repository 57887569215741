@mixin col($width-in-columns) {
  width: $col-width * $width-in-columns;
}

@mixin offset($width-in-columns) {
	margin-left: $col-width * $width-in-columns;
}

@mixin order($position) {
  order: $position;
}
