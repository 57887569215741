@mixin badge($background, $color-from-background: 'true') {

  border-color: $background;
  background-color: $background;

  @if ($color-from-background == 'true') {
    color: set-color-from-bg($background, darken($background, 60%), lighten($background, 60%));
  } @else {
    color: set-color-from-bg($background);
  }

  &.is-outline {
    color: $background;
  }
}
