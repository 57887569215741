.has-square-ratio {
  padding-top: 100%;
}

.has-4-3-ratio {
  padding-top: 75%;
}

.has-16-9-ratio {
  padding-top: 56.25%;
}

.has-40-ratio {
  padding-top: 40%;
}

.has-30-ratio {
  padding-top: 30%;
}



