/*------------------
    Sizes
-------------------*/

$fs-h1:     $fs-40;
$fs-h1-tab: $fs-50;

$fs-h2:     $fs-30;
$fs-h2-tab: $fs-30;

$fs-h3:     $fs-base;
$fs-h4:     $fs-12;
$fs-h5:     $fs-18;
$fs-h6:     $fs-base;

/*------------------
    Weights
-------------------*/

$fw-h1:     $fw-light;
$fw-h2:     $fw-light;
$fw-h3:     $fw-semi-bold;
$fw-h4:     $fw-semi-bold;
$fw-h5:     $fw-light;
$fw-h6:     $fw-semi-bold;

/*------------------
    FontFamilies
-------------------*/

$font-family-h1:     $sans-serif;
$font-family-h2:     $sans-serif;
$font-family-h3:     $sans-serif;
$font-family-h4:     $sans-serif;
$font-family-h5:     $sans-serif;
$font-family-h6:     $sans-serif;

/*------------------
    Text Transform
-------------------*/

$text-transform-h1:     none;
$text-transform-h2:     none;
$text-transform-h3:     uppercase;
$text-transform-h4:     uppercase;
$text-transform-h5:     none;
$text-transform-h6:     none;
