/*------------------
    Tab Toggles
-------------------*/

$color:         $beta;
$font-size:     $fs-base;
$font-weight:   $fw-semi-bold;
$hover-color:   $alpha;
$active-color:  $alpha;

$border-width:  0;
$border-color:  $border-color;

/*------------------
     Tab Cursor
--------------------*/

$cursor-color-1:  $alpha;
$cursor-color-2:  $alpha;
$cursor-height:   5px;

/*------------------
    Transition
--------------------*/

$content-transition-offset:    $spacer-30;
$content-transition-duration:  .5s;
$content-transition-animation: cubic-bezier(0.525, 0, 0, 1);

$cursor-transition-duration:   .5s;
$cursor-transition-animation:  cubic-bezier(0.525, 0, 0, 1);
