@mixin max-width($screen) {
  max-width: $screen;
}

@mixin padding-v($padding) {
    padding-top: $padding;
    padding-bottom: $padding;
}

@mixin padding-h($padding) {
    padding-right: $padding;
    padding-left: $padding;
}

@mixin margin-v($margin) {
    margin-top: $margin;
    margin-bottom: $margin;
}

@mixin margin-h($margin) {
    margin-right: $margin;
    margin-left: $margin;
}
