.credits {
  display: flex;
  position: fixed;

  top: 90px;
  right: 0;

  flex-direction: column;
  justify-content: space-between;

  width: 330px;
  height: calc(100vh - 90px);

  padding: 40px 40px 20px;

  transform: translateX(100%);
  transition: .5s;
  @include cubicInOut;

  background: $beta;

  color: $white;

  opacity: 0;
  visibility: hidden;

  z-index: 2;

  .button {
    position: absolute;
    top: 25px;
    left: 0;

    transform: translateX(-50%);
  }
}

.credit-section {
  p {
    margin-bottom: 20px;
  }

  img {
    width: 100%;
  }

  .row {
    @include margin-h(-5px);

    > * {
      @include padding-h(5px);
    }

    + .row {
      margin-top: 10px;
    }
  }

  + .credit-section {
    margin-top: 30px;
  }
}

.credit-list {
  font-size: $fs-12;

  strong {
    font-weight: $fw-semi-bold;
  }

  li {
    a {
      display: block;

      @include padding-v($spacer-15);

      border-top: 1px solid $gray-200;

      color: $gray-100;

      @include hover {
        color: $white;
      }
    }
  }
}

.has-credit-open {
  .credits {
    transform: none;
    opacity: 1;
    visibility: visible;
  }
}
