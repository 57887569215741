@keyframes scaleX {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 100%;
  }

  49% {
    transform: scaleX(0);
    transform-origin: 100% 100%;
  }

  50% {
    transform: scaleX(0);
    transform-origin: 0 0;
  }

  100% {
    transform: scaleX(1);
    transform-origin: 0 0;
  }
}
