/*******************************
        Header
*******************************/

$header-height:                 60px;
$header-height-tab:             90px;
$header-backgroud-color-1:      $white;
$header-backgroud-color-2:      $white;

$header-padding:                0 $spacer-10;
$header-padding-tab:            0 $spacer-30;

/*******************************
        Logo
*******************************/

$logo-width:                    50px;

/*******************************
        Header Nav
*******************************/

/******** Main Menu *******/

$main-menu-padding:             0;
$main-menu-padding-tab:         0 10px;

$main-menu-color:               $alpha;
$main-menu-hover-color:         $beta;
$main-menu-active-color:        $beta;
$main-menu-font-size:           $fs-base;
$main-menu-font-weight:         $fw-regular;
$main-menu-text-transform:      none;

$main-menu-item-padding:        0 10px;
$main-menu-item-padding-tab:    0 10px;
$main-menu-link-padding:        7px 0;
$main-menu-link-padding-tab:    20px 0;

$main-menu-cursor-width:        0;
$main-menu-cursor-color-1:      $alpha;
$main-menu-cursor-color-2:      $gamma;

/******** Moblie Menu *******/

$mobile-menu-background-color:  $white;
$mobile-menu-box-shadow:        0 0 30px rgba($black, .2);
$mobile-menu-item-border-top:   1px solid $border-color;
$mobile-menu-width:             80%;

/******** Submenu *******/

$sub-menu-spacer-top:           27px;

$sub-menu-color:                $alpha;
$sub-menu-active-color:         $alpha;
$sub-menu-hover-color:          $alpha;
$sub-menu-font-size:            $fs-base;
$sub-menu-font-weight:          $fw-regular;
$sub-menu-text-transform:       none;

$sub-menu-transition-duration:  .3s;
$sub-menu-border-radius:        3px;
$sub-menu-background-color:     $white;
$sub-menu-box-shadow:           0 0 40px rgba($black, .2);

$sub-menu-item-border-top:            0;
$sub-menu-item-padding-tab:           10px 15px;
$sub-menu-item-background-color-tab:  $white;
$sub-menu-item-hover-box-shadow:      none;

$sub-menu-arrow-width:          2px;
$sub-menu-arrow-color:          $gray-500;
$sub-menu-arrow-hover-color:    $alpha;

/******** Language Nav *******/

$social-menu-padding:           20px;
$social-menu-color:             $gray-500;
$social-menu-hover-color:       $gray-900;
$social-menu-font-size:         $fs-16;

$social-menu-item-padding:      0 5px;

/******** Language Nav *******/

$language-menu-padding:         20px;
$language-menu-padding-tab:     0 40px 0 20px;

$language-menu-color:           $gray-300;
$language-menu-active-color:    $gray-700;
$language-menu-hover-color:     $gray-500;
$language-menu-font-size:       $fs-base;
$language-menu-font-weight:     $fw-semi-bold;
$language-menu-text-transform:  none;







