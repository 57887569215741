.header-logo-container {
  margin-right: $spacer-50;

  span {
    display: block;
    max-width: 160px;

    padding-left: $spacer-15;

    border-left: 1px solid $beta;

    color: $beta;
    font-size: $fs-base;
    line-height: $lh12;
  }
}

.header-logo {
  @include margin-h($spacer-15);
}

.header-nav-main + .header-nav-main {
  li a {
    color: $beta;
  }
}

.header-nav {
  flex: 1;
}

.header-menu {
  display: flex;
  justify-content: space-between;

  width: 100%;
}

.header-nav-item-has-submenu {
  margin-right: $spacer-20;
  padding-right: $spacer-40 !important;
  padding-left: $spacer-20 !important;

  @include before {
    left: 0;

    width: 100%;
    height: 46px;

    border-radius: 50px;

    background-color: $gray-100;
  }

  @include after {
    right: $spacer-15;
    @include triangle-bottom(10px, 5px, $alpha);
  }

  &::before,
  &::after {
    top: 50%;
    transform: translateY(-50%);
  }
}


@include breakpoint('tab') {
  .header-nav-main {
    > li {
      > a {
        display: block;
      }

      > ul {
        padding: 21px;

        &::before {
          top: 0;
          height: 100%;
        }
      }
    }

    ul {
      width: 900px;

      columns: 3;
      column-gap: 0;
      // background-color: $white;
      // opacity: 1;
      // visibility: visible;

      @include after {
        top: 20px;
        left: calc(33.33% + 7px);

        width: calc(33.33% - 14px);
        height: calc(100% - 42px);

        border-width: 0 2px 0;
        border-style: solid;
        border-color: $gray-100;

        z-index: 1;
        pointer-events: none;

      }

      li {
        display: inline-block;

        a {
          display: flex;
          align-items: center;
        }

        img {
          width: 60px;
        }

        span {
          display: inline-block;
          padding: 10px;
          border-radius: 50px;
        }

        &:hover span {
          background-color: $gray-100;
        }
      }

      // @include before {
      //   top: 0;
      //   left: 33.33%;
      //   width: 2px;
      //   height: 100%;
      //   background-color: $gray-100;
      // }
    }
  }
}


