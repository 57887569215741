@import 'variables';
@import 'mixins';

/*******************************
        Main
*******************************/

.embed {
  position: relative;

  padding-top: 60%;

  background-color: $gray-300;

  @extend .has-backround-cover;

  &::before,
  &::after {
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    transition: .5s;
    @include InOutBack;

    opacity: .8;
  }

  @include before {
    width: 50px;
    height: 50px;

    border-radius: 50%;
    background-color: $white;
  }

  @include after {
    margin-left: 2px;
    @include triangle-right(14px, 16px, $gray-900);
  }

  > * {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: 1;
  }

  &.is-playing {
    background: $black !important;

    iframe {
      transform: none;
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }

    &::before,
    &::after {
      opacity: 0;
    }
  }

  iframe {
    transform: scale(.9);
    transition: .5s;
    transition-delay: .5s;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  @include hover {
    &:not(.is-playing) {
      &::before {
        transform: translate(-50%, -50%) scale(1.3);

        opacity: 1;
      }
    }
  }
}

/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {

}

@include breakpoint('tab') {

}

@include breakpoint('desk') {

}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {

}
