@import '../components/grid/variables';

//////////////////////////////////////////
// PMS /////////////////////////////////
//////////////////////////////////////////

//VARIABLES USED TO GENERATES PMS

//Values
$pms: (
	'-g': $gutter-width * 2,
	// '-g2': $gutter-width / 2,
	// '-n': 0,
	// '-a': auto,
	// '-5': 5px,
	'-10': 10px,
	// '-15': 15px,
	// '-20': 20px,
	// '-25': 25px,
	// '-30': 30px,
	// '-40': 40px,
	// '-50': 50px,
	// '-75': 75px,
	// '-100': 100px,
	// '-150': 150px
);

//Properties
$properties: (
  'm': margin,
  'p': padding
);

//Positions
$positions: (
	// 'l': left,
	// 'r': right,
	'b': bottom,
	't': top,
	// 'a': '',
	// 'h': (left, right),
	'v': (top, bottom)
);

//SET PROPERTIES OF PM DEPENDING ON POSITION
@mixin set-value($position, $position-value, $property, $value) {
	@if $position == 'a' {
    //ex: $position-value = '' => padding : 0
    #{$property}: $value !important;
  } @else if $position == 'h' or $position == 'v' {
    //ex: $position-value = (left,right) => padding-left : 0, padding-right : 0
    @each $pos in $position-value {
    	#{$property}-#{$pos}: $value !important;
    }
  } @else {
    //ex: $position-value = top => padding-top : 0
    #{$property}-#{$position-value}: $value !important;
  }
}

//LOOP TO SET ALONE PMS
@each $pm, $pm-value in $pms {
	@each $position, $position-value in $positions {
		@each $property, $property-value in $properties {
			.#{$property}#{$position}#{$pm} {
        //ex : ./p/t/n/
        @include set-value($position, $position-value, $property-value, $pm-value);
      }
    }
	}
}

//LOOP TO SET MEDIA PMS
@each $key, $value in $breakpoints {
	@include breakpoint($key) {
		@each $pm, $pm-value in $pms {
			@each $position, $position-value in $positions {
				@each $property, $property-value in $properties {
				  .#{$property}#{$position}#{$pm}-#{$key} {
            //ex : ./p/t/n/-/lg
            @include set-value($position, $position-value, $property-value, $pm-value);
          }
        }
	    }
		}
	}
}
