/*******************************
             Alignements
*******************************/

.is-aligned-center { text-align: center; }
.is-aligned-right { text-align: right; }

/*******************************
             Colors
*******************************/

@each $key, $value in $colors {
  &.has-color-#{$key} { color: $value;}
}

@each $key, $value in $neutrals {
  &.has-color-#{$key} { color: $value;}
}

@each $key, $value in $grays {
  &.has-color-#{$key} { color: $value;}
}


/*******************************
            Font Weights
*******************************/

.has-font-weight-thin  { font-weight: $fw-thin; }
.has-font-weight-light  { font-weight: $fw-light; }
.has-font-weight-bold   { font-weight: $fw-bold; }

/*******************************
             Text Shadow
*******************************/

.has-text-shadow {
  text-shadow: 0 0 10px rgba($black, .3);
}



/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {
  .has-2-columns { columns: 2; }
  .has-3-columns { columns: 3; }
}

@include breakpoint('tab') {

}

@include breakpoint('desk') {

}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {

}
