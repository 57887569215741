@import 'variables';
@import 'mixins';

/*******************************
        Main
*******************************/

.badge {
  /* Box */
  display: inline-block;

  @include margin-v(- ($badge-padding-v + $badge-border-width));
  padding: $badge-padding-v $badge-padding-h;

  /* Border */
  border-width: $badge-border-width;
  border-style: $badge-border-style;
  border-radius: $badge-border-radius;

  /* Fonts */
  font-size: inherit;
  font-weight: $badge-font-weight;
  letter-spacing: $badge-letter-spacing;
  text-transform: $badge-text-transform;

  /*******************************
             Colors
  *******************************/

  @each $key, $value in $badges-colors {
    &.is-#{$key} { @include badge($value); }
  }

  &.is-outline {
    background-color: transparent;
  }
}

/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {

}

@include breakpoint('tab') {

}

@include breakpoint('desk') {

}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {

}
