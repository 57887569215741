@import 'variables';
@import 'mixins';


/*******************************
        Main
*******************************/

[class*='section'] {
  position: relative;
  + [class*='section'] {
    // border-top: 1px solid $border-color;
  }
}

.section {
	padding: $section-padding-v-mob $section-padding-h-mob;
}

.section-v {
	@include padding-v($section-padding-v-mob);
}

.section-h {
  @include padding-h($section-padding-h-mob);
}


/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {

}

@include breakpoint('tab') {
  .section {
    padding: $section-padding-v $section-padding-h;
  }

  .section-v {
    @include padding-v($section-padding-v);
  }

  .section-h {
    @include padding-h($section-padding-h);
  }
}

@include breakpoint('desk') {

}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {

}

