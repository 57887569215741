@import 'variables';
@import 'mixins';

/*******************************
        Main
*******************************/

.card {
  transition: .5s;
  @include InOutBack;

  border-radius: $card-border-radius;

  color: $card-color;
  font-family: $card-font-family;
  font-size: $card-font-size;
  font-weight: $card-font-weight;
  letter-spacing: $card-letter-spacing;
  line-height: $card-line-height;
  text-transform: $card-text-transform;

  overflow: hidden;

  @each $key, $value in $colors {
    &.is-#{$key} { @include card($value); }
  }

  @each $key, $value in $neutrals {
    &.is-#{$key} { @include card($value); }
  }

  @each $key, $value in $grays {
    &.is-#{$key} { @include card($value); }
  }

  &.has-hover {
    @include hover {
      transform: $card-transform;
    }
  }
}

.card-thumbnail {
  position: relative;

  background-position: center;
  background-size: cover;

  &.is-cover {
    padding-top: 60%;
  }

  .card-content {
    position: absolute;
    bottom: 0;
    left: 0;

    max-width: 100%;

    color: $card-color-thumbnail;

    .card-title {
      color: $card-color-title-thumbnail;
    }

    .card-subtitle {
      color: $card-color-subtitle-thumbnail;
    }
  }
}

.card-title {
  color: $card-color-title;
  font-family: $card-font-family-title;
  font-size: $card-font-size-title;
  font-weight: $card-font-weight-title;
  letter-spacing: $card-letter-spacing-title;
  line-height: $card-line-height-title;
  text-transform: $card-text-transform-title;
}

.card-subtitle {
  color: $card-color-subtitle;
  font-family: $card-font-family-subtitle;
  font-size: $card-font-size-subtitle;
  font-weight: $card-font-weight-subtitle;
  letter-spacing: $card-letter-spacing-subtitle;
  line-height: $card-line-height-subtitle;
  text-transform: $card-text-transform-subtitle;
}

.card-title,
.card-subtitle {
  + p {
    margin-top: $card-title-spacer;
  }
}

.card-content {
  padding: $card-padding;

  // &:first-child:not(:only-child) {
  //   padding-bottom: $card-padding-inset;
  // }

  // &:last-child:not(:only-child) {
  //   padding-top: $card-padding-inset;
  // }

  +.card-content {
    border-top: $card-content-separator;
  }
}

/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {

}

@include breakpoint('tab') {
  .card {
    &.is-horizontal {
      display: flex;

      > * {
        flex: 1;
      }

      .card-thumbnail {
        padding: 0;
      }
    }
  }
}

@include breakpoint('desk') {

}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {

}
