@import 'variables';
@import '../headers/variables';
@import 'mixins';

/*******************************
        Main
*******************************/

.search {
  position: fixed;
  top: $header-height;
  left: 0;

  width: 100%;

  transform: translateY(-100%);
  transition: .5s;
  @include quartIn();

  opacity: 0;

  z-index: 5;
}

.has-search-open {
  .search {
    transform: none;
    @include quartOut();

    opacity: 1;
  }
}

/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {

}

@include breakpoint('tab') {
  .search {
    top: $header-height-tab;
  }
}

@include breakpoint('desk') {

}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {

}
