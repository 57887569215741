@import 'variables';
@import 'mixins';

.button {
  /*******************************
             Base
  *******************************/

  /* Box */
  display: inline-block;

  position: relative;

  padding: $button-padding-v $button-padding-h;

  transition: background-color .3s, color .3s, border-color .3s, box-shadow .3s;

  border: $button-border-width solid transparent;
  border-radius: $button-border-radius;

  background-color: transparent;

  /* Text */
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  text-transform: $button-text-transform;

  vertical-align: top;

  user-select: none;

  /*******************************
             Colors
  *******************************/

  &.is-default { @include button($gray-200, false); }

  @each $key, $value in $colors {
    &.is-#{$key} { @include button($value); }
    // &.is-gradient-#{$key} { @include button-gradient($value, darken($value, 10%)); }
  }

  @each $key, $value in $infos {
    &.is-#{$key} { @include button($value); }
    // &.is-gradient-#{$key} { @include button-gradient($value, darken($value, 10%)); }
  }

  @each $key, $value in $neutrals {
    &.is-#{$key} { @include button($value, false); }
    // &.is-gradient-#{$key} { @include button-gradient($value, darken($value, 10%)); }
  }

  @each $key, $value in $grays {
    &.is-#{$key} { @include button($value, false); }
    // &.is-gradient-gray-#{$key} { @include button-gradient($value, darken($value, 10%)); }
  }

  &.is-transparent {
    background-color: transparent;
    color: $button-color-dark;
  }

  // @each $key, $values in $buttons-gradients {
  //   &.is-gradient-#{$key} { @include button-gradient(nth($values, 1), nth($values, 2), false); }
  // }

  /*******************************
             Sizes
  *******************************/

  @each $key, $value in $buttons-sizes {
    &.is-#{$value} {
      /* Box */
      // padding: $button-#{$key}-padding-v $button-#{$key}-padding-h;
      padding: map-get($buttons-paddings, $key);

      border-radius: map-get($buttons-border-radius, $key);

      /* Text */
      font-size: map-get($buttons-font-sizes, $key);
      font-weight: map-get($buttons-font-weights, $key);
    }
  }

  /*******************************
             Styles
  *******************************/

  &.is-rounded {
    border-radius: $button-rounded-border-radius;
  }

  &.is-square {
    width: $button-height;
    height: $button-height;

    padding: $button-padding-v;

    @each $key, $value in $buttons-sizes {
      &.is-#{$value} {
        width: map-get($buttons-heights, $key);
        height: map-get($buttons-heights, $key);

        padding: map-get($buttons-paddings-v, $key);
      }
    }
  }

  &[class*='is-gradient-'].is-outline {
  //   &::before { border-radius: $button-border-radius; }
  //   &::after { border-radius: calc(#{$button-border-radius} - #{$button-border-width}); }

  //   @each $key, $value in $buttons-sizes {
  //     &.is-#{$value} {
  //       &::before { border-radius: map-get($buttons-border-radius, $key); }
  //       &::after { border-radius: calc(map-get($buttons-border-radius, $key) - #{$button-border-width}); }
  //     }
  //   }

  //   &.is-rounded {
  //     &::before,
  //     &::after {
  //       border-radius: $button-rounded-border-radius;
  //     }
  //   }
  }


  /*******************************
             Icons
  *******************************/

  @include hover {
    .css-icon.is-arrow {
      &::before {
        transform: translate(-30%, -50%);
      }
      &::after {
        transform: translateX(30%) rotate(45deg);
      }
    }

    .css-icon.is-chevron {
      &::after {
        transform: translate(-40%, -50%) rotate(45deg);
      }
    }

    .css-icon.is-close {
      transform: rotate(135deg);
    }

    .css-icon.is-plus {
      transform: scale(1.2);
    }

    .css-icon.is-minus::before {
      transform: translate(-50%, -50%) scaleX(.8);
    }
  }

  &.is-disabled,
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.is-loading {
    overflow: hidden;
    z-index: 0;
    pointer-events: none;

    @include before {
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      z-index: -1;

      animation: scaleX 1.5s infinite;
    }
  }
}


/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {

}

@include breakpoint('tab') {

}

@include breakpoint('desk') {

}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {

}
