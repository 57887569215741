@import 'variables';
@import 'mixins';

/*******************************
        Main
*******************************/

.alert {
  padding: $padding;
  border-radius: $border-radius;

  @include alert($gray-500);

  @extend .wysiwyg;

  @each $key, $value in $infos {
    &.is-#{$key} { @include alert($value); }
  }
}

/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {

}

@include breakpoint('tab') {

}

@include breakpoint('desk') {

}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {

}
