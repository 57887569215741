@mixin scroll {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}

@mixin scroll-x {
  white-space: nowrap;
  overflow-x: scroll;

  @include scroll;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
}


@mixin scrollbar-y($scrollbar-color-1: $alpha, $scrollbar-color-2: $gamma, $scrollbar-background: rgba($black, .1)) {
  overflow-y: scroll;

  @include scroll;

  &::-webkit-scrollbar {
    width: 6px;
    background-color: $scrollbar-background;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: linear-gradient($scrollbar-color-1, $scrollbar-color-2);
  }
}
