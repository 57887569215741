@import 'variables';
@import 'mixins';

/*******************************
        Main
*******************************/

.css-icon {
  display: block;

  position: relative;

  @include icon($gray-900);

  &.is-minus,
  &.is-plus,
  &.is-arrow,
  &.is-close,
  &.is-burger,
  &.is-mouse,
  &.is-search {
    @include before {
      top: 50%;
      left: 50%;

      width: $icon-size;
      height: $icon-thickness;

      transform: translate(-50%, -50%);
    }
  }

  &::after {
    top: 50%;
    left: 50%;
  }

  &.is-minus::before {
    transform: translate(-50%, -50%) scaleX(1.2);
  }

  &.is-burger {
    span {
      @include before {
        top: 50%;
        transform: translate(-50%, -6px);
      }

      @include after {
        bottom: 50%;
        transform: translate(-50%, 6px);
      }

      &::before,
      &::after {
        left: 50%;

        width: $icon-size;
        height: $icon-thickness;
      }
    }
  }

  &.is-close,
  &.is-plus {
    @include after {
      width: $icon-size;
      height: $icon-thickness;

      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  &.is-check,
  &.is-chevron,
  &.is-arrow {
    @include after {
      width: $icon-size / 2;
      height: $icon-size / 2;

      border-width: $icon-thickness $icon-thickness 0 0;
      border-style: solid;

      background-color: transparent !important;
    }
  }

  &.is-arrow::after {
    transform: rotate(45deg);
    transform-origin: 100% 0;
  }

  &.is-mouse {
    @include after {
      width: $icon-size * 2 / 3;
      height: $icon-size;

      transform: translate(-50%, -50%);

      border-width: $icon-thickness;
      border-style: solid;
      border-radius: $icon-size;

      background-color: transparent !important;
    }

    &::before {
      top: -$icon-size / 4;

      width: $icon-thickness;
      height: $icon-size / 4;
    }
  }

  &.is-search {
    @include after {
      width: $icon-size / 2;
      height: $icon-size / 2;

      transform: translate(-100%, -50%);

      border-width: $icon-thickness;
      border-style: solid;
      border-radius: $icon-size;

      background-color: transparent !important;
    }

    &::before {
      width: $icon-size / 2;

      transform: translate(0, -50%);
    }
  }

  &.is-chevron::after {
    transform: translate(-60%, -50%) rotate(45deg);
  }

  &.is-check::after {
    width: $icon-size * 9 / 10;
    height: $icon-size * 4 / 10;

    transform: translate(-50%, -60%) rotate(-45deg);
    border-width: 0 0 $icon-thickness $icon-thickness;
  }

  &.is-close { transform: rotate(45deg); }
  &.is-bottom { transform: rotate(90deg);}
  &.is-left { transform: rotate(180deg); }
  &.is-top { transform: rotate(270deg); }
  &.is-search { transform: translate($icon-size / 8, $icon-size / 8) rotate(45deg); }

  @each $key, $value in $neutrals {
    &.is-#{$key} { @include icon($value); }
  }

  @each $key, $value in $colors {
    &.is-#{$key} { @include icon($value); }
  }

  @each $key, $value in $grays {
    &.is-#{$key} { @include icon($value); }
  }

  &.is-small {
    &::before,
    &::after {
      width: $icon-sm-size;
    }
  }
}

[class*='has-icon-'] {
  display: flex;

  .css-icon {
    width: $icon-size;
  }
}

.has-icon-right,
.has-icon-left {
  align-items: center;
}

.has-icon-right {
  .icon,
  .css-icon {
    margin-left: $spacer-10;
  }
}

.has-icon-left {
  .icon,
  .css-icon {
    margin-right: $spacer-10;
  }
}

.has-menu-open {
  .css-icon.is-burger {
    &::before {
      opacity: 0;
    }

    span {
      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, 50%) rotate(-45deg);
      }
    }
  }
}


/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {

}

@include breakpoint('tab') {

}

@include breakpoint('desk') {

}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {

}
