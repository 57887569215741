.modal .button-abs {
  position: absolute;
  bottom: 0;
  left: 50%;

  transform: translate(-50%, 50%);
}

.modal-content-logo {
  justify-content: center;
}
