@import 'variables';
@import 'mixins';

/*******************************
        Main
*******************************/

.nav {
  position: relative;

  color: $nav-color;
  font-family: $nav-font-family;
  font-size: $nav-font-size;
  font-weight: $nav-font-weight;
  letter-spacing: $nav-letter-spacing;
  line-height: $nav-line-height;
  text-transform: $nav-text-transform;

  ul {
    position: relative;
    @include scroll-x;
  }

  li {
    display: inline-block;

    margin-right: $nav-item-spacer;
    padding: $nav-item-padding;

    transition: .5s;
    @include cubicInOut;

    z-index: 0;

    @include hover {
      color: $nav-hover-color;
    }

    &.is-active {
      color: $nav-active-color;

      .nav-progress {
        opacity: 1;
      }
    }
  }

  &.has-border {
    @include before {
      bottom: 0;
      left: 0;

      width: 100%;
      border-bottom: $nav-border;
    }

    li {
      border-width: $nav-item-border-width;
      border-style: $nav-item-border-style;
      border-color: $nav-item-border-color;

      @include hover {
        border-color: $nav-item-hover-border-color;
      }

      &.is-active {
        border-color: $nav-item-active-border-color;
      }
    }
  }

  &.has-pills {
    color: $nav-pill-color;
    font-family: $nav-pill-font-family;
    font-size: $nav-pill-font-size;
    font-weight: $nav-pill-font-weight;
    text-transform: $nav-pill-text-transform;

    @include margin-h(-($nav-pill-border-width + $nav-pill-box-shadow-width));

    ul {
      @include padding-v($nav-pill-border-width + $nav-pill-box-shadow-width);
    }

    li {
      margin-right: $nav-pill-spacer;
      padding: $nav-pill-padding;

      border-width: $nav-pill-border-width;
      border-style: $nav-pill-border-style;
      border-radius: $nav-pill-border-radius;
      border-color: $nav-pill-border-color;

      background-color: $nav-pill-background;

      box-shadow: $nav-pill-box-shadow;

      &:first-child {
        margin-left: $nav-pill-border-width + $nav-pill-box-shadow-width;
      }

      @include hover {
        border-color: $nav-pill-hover-border-color;

        background-color: $nav-pill-hover-background;
        color: $nav-pill-hover-color;

        box-shadow: $nav-pill-hover-box-shadow;
      }

      &.is-active {
        border-color: $nav-pill-active-border-color;

        background-color: $nav-pill-active-background;
        color: $nav-pill-active-color;

        box-shadow: $nav-pill-active-box-shadow;
      }
    }
  }
}

.nav-progress {
  position: absolute;
  top: 0;
  left: 0;

  width: 50%;
  height: 100%;

  background-color: $gray-100;

  opacity: 0;

  z-index: -1;
}


/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {

}

@include breakpoint('tab') {

}

@include breakpoint('desk') {

}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {
  .nav {
    // ul {
    //   @include scroll-x;
    // }
  }
}
