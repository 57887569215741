@import 'variables';
@import 'mixins';

/*******************************
        Main
*******************************/

.anchor {
  display: block;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 1px;
}

/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {

}

@include breakpoint('tab') {

}

@include breakpoint('desk') {

}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {

}
