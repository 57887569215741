@import 'variables';
@import 'mixins';

.form {
    transition: 0.5s;
}

.input {
    width: 100%;
    height: $input-height;
    //
    padding: 0 $spacer-20;
    //
    transition: 0.5s;
    //
    border-width: $input-border-width;
    border-style: $input-border-style;
    border-radius: 50px;
    border-color: $input-border;
    background-color: $input-bg;
    color: $input-color;
    //
    font-size: 16px;
    font-weight: $fw-base;
    text-align: left;
    //

    &::placeholder {
      color: $input-placeholder;
    }

    // &:focus {
    //     background-color: $input-focus-bg;
    //     border-color: $input-focus-border;
    // }

    + & {
        margin-top: $spacer-10;
    }

    &:not(.is-transparent):not(:read-only):focus {
        border-color: $input-focus-border;
        background-color: $input-focus-bg;
    }

    &.is-transparent {
        border-color: transparent;
        background-color: transparent;
        color: $white;
        &::placeholder {
            color: rgba($white, 0.6);
        }
    }

    &.is-file {
        display: none;
        + label {
            // @extend .btn, .is-success;
            justify-content: flex-start;
            width: 100%;
            height: $input-height;
            transition: 0.5s;
            font-size: $fs-14;

            .icon {
                display: block;
                transition: 0.5s;
                color: $white;
                // font-size: $fs-18;
            }

            .icon-check {
                transform: translateY(-50%) rotateZ(0) scale(1);
                transition-delay: 0.2s;
            }

            .icon-attachment {
                opacity: 0;
            }

            .filename {
                padding-right: $spacer-50;
            }
        }

        &.is-empty {
            + label {
                // @extend .btn.is-gray-200;
                color: $text-color;

                .icon {
                    color: $text-color;
                }

                .icon-check {
                    transform: translateY(-50%) rotateZ(-45deg) scale(0);
                    transition-delay: 0s;
                    opacity: 0;
                }

                .icon-attachment {
                    transition-delay: 0.2s;
                    opacity: 1;
                }
            }
        }
        &.has-error {
            + label {
                background-color: $danger;
            }
        }
    }
}

.form-control .wrapper {
    position: relative;
    &::before, &::after {
        display: block;
        position: absolute;
        top: 50%;
        right: $spacer-10;
        width: 2px;
        height: 2px;
        background-color: $gray-400;
        content: '';
        opacity: 0;
        z-index: 1;
        pointer-events: none;
    }
    &::before {
        transform: translate(-20px) rotateZ(-45deg);
        transition: background-color 0.3s, transform-origin 0.3s, transform 0.3s, opacity 0.3s, border-radius 0s;
    }
    &::after {
        transform: translate(-20px) rotateZ(45deg);
        transition: 0.2s;
    }
    .reset {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 100%;
        opacity: 0;
    }
}

//FILE

.file-zone {
    min-height: 98px;
    text-align: center;
}

.files-container {
    display: flex;
    border-top: 1px solid $gray-500;
}

//SEARCH

.search-wrapper {
    position: relative;

    .icon {
        position: absolute;
        top: 50%;
        left: $spacer-20;
        transform: translateY(-50%);
    }

    .input {
        padding-left: $spacer-50;
    }
}

//SELECT

.select-wrapper {
    position: relative;
    z-index: 2;

    &.is-active {
        z-index: 3;
    }

    .input {
        padding-right: $spacer-50;
        cursor: pointer;
    }

    &::after {
        position: absolute;
        top: 50%;
        right: 0;
        padding: 16px 18px 16px;
        transform: translateY(-50%);
        border-left: 1px solid $gray-300;
        color: $gray-400;
        font-family: 'icomoon';
        content: '\e916';
        z-index: 1;
        pointer-events: none;
    }

    .select-desktop {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        &.is-open {
            .input {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-color: $input-focus-border;
                background-color: $input-focus-bg;
            }
        }

        ul {
            max-height: 300px;
            transition: 0.2s;
            border-width: 0 2px 2px;
            border-style: solid;
            border-color: $input-focus-border;
            overflow: auto;
        }
        li {
            padding: $spacer-15 $spacer-20;
            transition: 0.2s;
            background-color: $white;
            cursor: pointer;
        }
    }
}

input, textarea {
    &.input:read-only {
        border-color: $input-border;
        background-color: $input-bg;
    }
}

//TEXTAREA

.textarea {
    @extend .input;
    height: auto;
    @include padding-v($input-padding-x);
    &.is-small {
        min-height: 100px;
    }

    &.is-medium {
        min-height: 200px;
    }
}

//LABEL

.label {
    display: block;
    font-size: $fs-14;
    line-height: $lh12;
    &::first-letter {
        text-transform: uppercase;
    }
    // font-weight: $fw-bold;
}

//VALIDATION

.has-validation {
    .input {
        padding: 0 $spacer-50 0 $spacer-20;
    }
    .textarea {
        padding: $spacer-20 $spacer-50 $spacer-20 $spacer-20;
    }
    .select-wrapper {
        .wrapper {
            &::before, &::after {
                right: $spacer-50 + $spacer-10;
            }
        }
        .input {
            padding-right: $spacer-100;
        }
    }
    .input-group {
        padding-right: $spacer-50;
    }
}
.is-error {
    .wrapper {
        &::before, &::after {
            background-color: $danger;
            opacity: 1;
        }
        &::before {
            transform: translate(-9px, 8px) scale(1.5);
            transform-origin: 50% 100%;
            transition: background-color 0.3s, transform-origin 0.3s, transform 0.3s, opacity 0.3s, border-radius 0.1s 0.2s;
            border-radius: 2px;
        }
        &::after {
            transform: translate(-10px, 3px) rotateZ(90deg) scaleX(7);
            transform-origin: 100% 50%;
        }
    }
    .input {
        border-color: $danger;
    }
}
.is-valid {
    .input {
        border-color: $success;
    }
    .wrapper {
        &::before, &::after {
            transform-origin: 100% 0;
            background-color: $success;
            opacity: 1;
        }
        &::before {
            transform: translate(-20px, 7px) rotateZ(45deg) scaleX(5);
        }
        &::after {
            transform: translate(-20px, 7px) rotateZ(135deg) scaleX(9);
        }
    }
}

//STRUCTURE

.form-control {
    &.is-optional {
        .label {
            position: relative;
            &::before {
                display: block;
                position: absolute;
                right: 0;
                bottom: $spacer-5;
                color: $gray-400;
                text-transform: capitalize;
                content: 'Optionnel';
            }
        }
    }
}

.col-form, .col-form-large {
    & + & {
        padding-top: $spacer-20;
    }
}

.col-form {
    // @extend .col-md-6;
}

.col-form-large {
    // @extend .col-12;
}

.form-group {
    position: relative;

    & + & {
        margin-top: $spacer-20;
    }

    &.has-separator {
        margin-top: $spacer-30;
        padding-top: $spacer-30;
        &::before {
            position: absolute;
            top: 0;
            right: $spacer-30;
            left: $spacer-30;
            height: 1px;
            background-color: $gray-600;
            content: '';
        }
    }
}

.input-group {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .item {
        @include margin-v($spacer-5);
        &:not(:last-of-type) {
            margin-right: $spacer-20;
        }
    }
}

//PREVENT AUTOFILL COLOR

@-webkit-keyframes autofill {
    to {
        background: $white;
        color: $input-color;
    }
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

@include breakpoint('tab') {

    .files-container {
        border-top: 0;
        border-left: 1px solid $gray-500;
    }

    .file-zone {
        text-align: left;
    }

    .col-form, .col-form-large {
        & + & {
            padding-top: 0;
        }
    }

}
@include breakpoint('desk') {


    .select-wrapper {
        .select-desktop {
            display: block;
            li {
                &:hover, &:focus {
                    background-color: $gray-200;
                }
            }
        }
        &:hover {
            .wrapper {
                &::before, &::after {
                    opacity: 0;
                }
            }
        }
    }

    .form-control .wrapper:hover {
        .input:not(:read-only) {
            border-color: $input-focus-border;
            background-color: $input-focus-bg;
        }
        &.is-resetable:not(.is-empty) {
            &::before, &::after {
                transform-origin: 50% 50%;
                opacity: 1;
            }
            &::before {
                transform: translate(-15px) rotateZ(-45deg) scaleX(12);
                transition: background-color 0.3s, transform-origin 0.3s, transform 0.3s, opacity 0.3s, border-radius 0s;
                border-radius: 0;
                background-color: $gray-700;
            }
            &::after {
                transform: translate(-15px) rotateZ(45deg) scaleX(12);
                background-color: $gray-700;
            }
        }
    }
}

/*******************************
        Breakpoints Down
*******************************/

/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('tab') {
  .input {
    font-size: $input-font-size;
  }
}
