@import 'variables';
@import 'mixins';

/*******************************
        Main
*******************************/

.carousel-container {
  position: relative;

  &.has-columns {
    @extend .row;

    .carousel-item {
      @include padding-h($gutter-width);
    }

    .carousel-controls {
      top: 0;
      right: $gutter-width;
      bottom: auto;
      left: auto;

      transform: translateY(calc(-100% - 15px));
    }
  }
}

.carousel {
  position: relative;
  overflow: hidden;

  &.is-fade {
    .carousel-item {
      opacity: 0;

      &.is-active {
        opacity: 1;
      }
      &:nth-child(n+2) {
        position: absolute;
      }
    }
  }

  @include hover {
    .carousel-controls {
      opacity: 1;
    }
  }

  .carousel-controls {
    transition: .5s;
    opacity: .5;
  }
}

.carousel-items {
  @include cubicInOut;

  overflow: hidden;
}

.carousel-item {
  position: relative;

  float: left;

  transition: 1s;
  @include cubicInOut;

  // overflow: hidden;

  img {
    width: 100%;
    pointer-events: none;
  }

  &.is-active {
    .carousel-item-content > * {
      transform: none;
      opacity: 1;
    }
  }

  // &.is-prev .carousel-item-content { transform: translateX(-40vw); }
  // &.is-next .carousel-item-content { transform: translateX(40vw); }

  // &.is-prev,
  // &.is-next {
  //   .carousel-item-content {
  //     @include cubicIn;

  //     opacity: 0;
  //   }
  // }
}

.carousel-item-content {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 100%;

  padding: $spacer-50;

  transform: translate(-50%, -50%);

  > * {
    transform: translateY(-20px);
    transition: .5s;
    transition-delay: 1s;
    @include quartOut;

    opacity: 0;

    @for $i from 0 through 3 {
      &:nth-child(#{$i}) {
        transition-delay: (1s + $i * .2s);
      }
    }
  }
}

.carousel-dots {
  display: flex;
  justify-content: center;

  padding-top: $spacer-10;
}

.carousel-dot {
  width: 10px;
  height: 10px;

  transition: .5s;
  @include InOutBack;

  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  border-color: $gray-900;

  + .carousel-dot {
    margin-left: 5px;
  }

  &.is-active {
    transform: scale(1.2);
    border-color: $alpha;
  }

  @include hover {
    border-color: $alpha;
  }
}

.carousel-controls {
  position: absolute;

  bottom: 20px;
  left: 20px;
}

.carousel-control {
  * {
    pointer-events: none;
  }
}

/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {

}

@include breakpoint('tab') {

}

@include breakpoint('desk') {

}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {

}
