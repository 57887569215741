@mixin breakpoint($key, $down: true) {
  @if ($down == true) {
    @media (min-width: map-get($breakpoints, $key)) {
      @content;
    }
  } @else {
    @media (max-width: map-get($breakpoints, $key) - 1) {
      @content;
    }
  }
}
