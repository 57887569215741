@import 'variables';
@import 'mixins';


/*******************************
          Container
*******************************/

.container {
  max-width: $container-max-width;
  @include margin-h(auto);
}

.container,
.container-fluid {
  @include padding-h($gutter-width);

  .row { @include margin-h(-$gutter-width); }
}


/*******************************
              Row
*******************************/

.row {
  display: flex;
  flex-wrap: wrap;
  @include margin-h(-$gutter-width);

  @each $key, $value in $breakpoints {
    @include breakpoint($key) {
      @if $enable-reverse == true {
        &.is-#{$key}-reverse { flex-direction: row-reverse; }
      }

      @if $enable-vertical-align == true {
        &.is-#{$key}-top-aligned { align-items: flex-start; }
        &.is-#{$key}-bottom-aligned { align-items: flex-end; }
        &.is-#{$key}-center-aligned { align-items: center; }
      }

      &.is-#{$key}-auto {
        .col {
          flex: 1;
        }
      }
    }
  }
}

/*******************************
      Col, offset & order
*******************************/

.col {
  width: 100%;
  @include padding-h($gutter-width);
}

[class*='col-'] {
  width: 100%;
  @include padding-h($gutter-width);
}

@each $key, $value in $breakpoints {
  @for $i from 1 through $grid-columns {
    $name: '#{$key}-#{$i}';

    .col-#{$i} { @include col($i); }

    @include breakpoint($key) {
      .col-#{$name} { @include col($i); }

      @if $enable-offset == true {
        .has-offset-#{$name} { @include offset($i); }
      }

      @if $enable-ordering == true {
        .has-order-#{$name} { @include order($i); }
      }
    }
  }
}


