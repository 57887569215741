@import 'variables';
@import 'mixins';

/*******************************
        Main
*******************************/

.notifications {
  position: fixed;
  top: 0;
  right: 0;

  padding: $padding;

  z-index: 20;

  pointer-events: none;
}

.notification {
  position: relative;

  width: 100%;
  max-width: 300px;

  padding: $padding;

  transform: translateX(100%);
  transition: $transition-duration;
  @include InBack;

  border-radius: $border-radius;

  background-color: $background-color;

  font-size: $font-size;
  letter-spacing: $letter-spacing;
  line-height: $line-height;

  box-shadow: 0 0 20px rgba($black, .2);

  opacity: 0;

  overflow: hidden;

  pointer-events: all;

  * {
    pointer-events: none;
    user-select: none;
  }

  @each $key, $value in $infos {
    &.is-#{$key} { @include notification($value); }
  }

  @include before {
    top: 0;
    left: 0;

    width: $border-width;
    height: 100%;
  }

  &.is-active {
    transform: none;
    opacity: .8;
    @include OutBack;

    @include hover {
      transform: scale(1.05);
      box-shadow: 0 0 20px rgba($black, .3);
      opacity: 1;
    }
  }

  + .notification {
    margin-top: $spacer-10;
  }
}

.notification-title {
  font-weight: $font-weight;
}


/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {

}

@include breakpoint('tab') {

}

@include breakpoint('desk') {

}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {

}
