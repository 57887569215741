@import '../components/headers/variables';

main {
  display: block;

  padding-top: $header-height;
}


/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {
  main {
    padding-top: $header-height-tab;
  }
}

@include breakpoint('tab') {

}

@include breakpoint('desk') {

}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {

}

