@import 'variables';
@import 'mixins';

/*******************************
        Main
*******************************/

.tab-toggles {
  display: flex;
  position: relative;

  @include scroll-x;

  @include before {
    bottom: 0;
    left: 0;

    width: 100%;
    height: $border-width;

    background-color: $border-color;
  }
}

.tab-toggle {
  display: block;

  margin-right: $spacer-30;
  padding: $spacer-15 0;

  color: $color;

  font-size: $font-size;
  font-weight: $font-weight;

  user-select: none;

  &.is-active { color: $active-color; }

  @include hover { color: $hover-color; }
}

.tab-cursor {
  position: absolute;

  bottom: 0;
  left: 0;

  height: $cursor-height;

  transition: $cursor-transition-duration $cursor-transition-animation;

  background: linear-gradient(45deg, $cursor-color-1, $cursor-color-2);

  z-index: 1;
  pointer-events: none;
}

.tab-container {
  margin-top: $spacer-20;

  transition: .5s;
}

.tab-content {
  display: none;

  transition: $content-transition-duration $content-transition-animation;
  opacity: 0;

  &.is-prev { transform: translateX(-$content-transition-offset); }
  &.is-next { transform: translateX($content-transition-offset); }

  &.is-active {
    transform: none;
    opacity: 1;
  }
}


/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {

}

@include breakpoint('tab') {

}

@include breakpoint('desk') {

}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {

}
