/*------------------
    Default
-------------------*/

$button-color-light:        $white;
$button-color-dark:         $alpha;

$button-border-radius:      5px;
$button-font-size:          $fs-base;
$button-font-weight:        $fw-semi-bold;
$button-text-transform:     none;

/*------------------
    Rounded
-------------------*/

$button-rounded-border-radius: 40px;

/*------------------
    Outlined
-------------------*/

$button-border-width:       2px;

/*------------------
    Xmall
-------------------*/

$button-xs-border-radius:    3px;
$button-xs-font-size:        $fs-base;
$button-xs-font-weight:      $fw-regular;

/*------------------
    Small
-------------------*/

$button-sm-border-radius:    3px;
$button-sm-font-size:        $fs-11;
$button-sm-font-weight:      $fw-semi-bold;

/*------------------
    Large
-------------------*/

$button-lg-border-radius:    3px;
$button-lg-font-size:        $fs-13;
$button-lg-font-weight:      $fw-semi-bold;

/*------------------
    Xlarge
-------------------*/

$button-xl-border-radius:    3px;
$button-xl-font-size:        $fs-14;
$button-xl-font-weight:      $fw-semi-bold;


/*******************************
      Padding & Heights
*******************************/

/*------------------
    Default
-------------------*/

$button-height:              45px;
$button-padding-v:           15px;
$button-padding-h:           25px;

/*------------------
    Xsmall
-------------------*/

$button-xs-height:           23px;
$button-xs-padding-v:        5px;
$button-xs-padding-h:        10px;

/*------------------
    Small
-------------------*/

$button-sm-height:           28px;
$button-sm-padding-v:        10px;
$button-sm-padding-h:        20px;

/*------------------
    Large
-------------------*/

$button-lg-height:           57px;
$button-lg-padding-v:        20px;
$button-lg-padding-h:        30px;

/*------------------
    Xlarge
-------------------*/

$button-xl-height:           68px;
$button-xl-padding-v:        25px;
$button-xl-padding-h:        35px;


/*******************************
             Mappings
*******************************/

$buttons-sizes: (
  'xs': 'xsmall',
  'sm': 'small',
  'lg': 'large',
  'xl': 'xlarge',
);

$buttons-heights: (
  'xs': $button-xs-height,
  'sm': $button-sm-height,
  'lg': $button-lg-height,
  'xl': $button-xl-height,
);

$buttons-border-radius: (
  'xs': $button-xs-border-radius,
  'sm': $button-sm-border-radius,
  'lg': $button-lg-border-radius,
  'xl': $button-xl-border-radius,
);

$buttons-font-sizes: (
  'xs': $button-xs-font-size,
  'sm': $button-sm-font-size,
  'lg': $button-lg-font-size,
  'xl': $button-xl-font-size,
);

$buttons-font-weights: (
  'xs': $button-xs-font-weight,
  'sm': $button-sm-font-weight,
  'lg': $button-lg-font-weight,
  'xl': $button-xl-font-weight,
);

$buttons-paddings: (
  'xs': $button-xs-padding-v $button-xs-padding-h,
  'sm': $button-sm-padding-v $button-sm-padding-h,
  'lg': $button-lg-padding-v $button-lg-padding-h,
  'xl': $button-xl-padding-v $button-xl-padding-h,
);

$buttons-paddings-v: (
  'xs': $button-xs-padding-v,
  'sm': $button-sm-padding-v,
  'lg': $button-lg-padding-v,
  'xl': $button-xl-padding-v,
);

$buttons-paddings-h: (
  'xs': $button-xs-padding-h,
  'sm': $button-sm-padding-h,
  'lg': $button-lg-padding-h,
  'xl': $button-xl-padding-h,
);

$buttons-gradients: (
  // 'alpha-beta': ($alpha, $beta, $gray-700),
  // 'beta-gamma': ($beta, $gamma, $gray-700),
  // 'gamma-delta': ($gamma, $delta, $gray-700),
  // 'delta-epsilon': ($delta, $epsilon, $gray-700),
  // 'epsilon-alpha': ($epsilon, $alpha, $gray-700),
);

