/*******************************
             Base
*******************************/

$color:           $alpha;
$color-inverse:   $white;
$font-family:     $sans-serif;
$font-size:       $fs-base;
$font-weight:     $fw-base;
$letter-spacing:  0;
$line-height:     $lh18;


/*******************************
             Spacers
*******************************/
$spacer:          $spacer-30;
$spacer-large:    $spacer-20;
$spacer-xlarge:   $spacer-40;


/*******************************
             Links
*******************************/

$color-link:        $link-color;
$font-weight-link:  $fw-black;
$color-link-hover:  $link-color;

$border-width-link: 0 0 2px;
$border-style-link: solid;
$border-color-link: $gray-100;

$border-color-link-hover: rgba($link-color, .5);


/*******************************
             Strongs
*******************************/

$color-strong:        $gray-900;
$font-weight-strong:  $fw-bold;


/*******************************
             Lists
*******************************/

$list-style-ul:        disc;
$list-style-type-ol:   decimal;

/*******************************
             Blockquotes
*******************************/

$color-bq:              $gray-900;
$font-family-bq:        $sans-serif;
$font-size-bq:          $fs-20;
$font-weight-bq:        $fw-base;
$letter-spacing-bq:     0;
$line-height-bq:        $lh2;

$padding-bq:            0 0 0 $spacer-40;

$border-width-bq:       3px;
$border-color-bq-1:     $alpha;
$border-color-bq-2:     $gamma;


/*******************************
             Hr
*******************************/

$border-color-hr:    $gray-200;
$margin-hr:   $spacer-20;


/*******************************
             Titles
*******************************/

/* H1 */
$color-h1:           $gray-900;
$font-family-h1:     $sans-serif;
$font-size-h1:       $fs-30;
$font-weight-h1:     $fw-bold;
$letter-spacing-h1:  $ls-05;
$line-height-h1:     $lh12;

/* H2 */
$color-h2:           $beta;
$font-family-h2:     $sans-serif;
$font-size-h2:       $fs-20;
$font-weight-h2:     $fw-regular;
$letter-spacing-h2:  0;
$line-height-h2:     $lh12;

/* H3 */
$color-h3:           $alpha;
$font-family-h3:     $sans-serif;
$font-size-h3:       $fs-base;
$font-weight-h3:     $fw-semi-bold;
$letter-spacing-h3:  $ls-05;
$line-height-h3:     $lh12;

/* H4 */
$color-h4:           $gray-700;
$font-family-h4:     $sans-serif;
$font-size-h4:       $fs-18;
$font-weight-h4:     $fw-semi-bold;
$letter-spacing-h4:  $ls-05;
$line-height-h4:     $lh12;

/* H5 */
$color-h5:           $gray-500;
$font-family-h5:     $sans-serif;
$font-size-h5:       $fs-16;
$font-weight-h5:     $fw-semi-bold;
$letter-spacing-h5:  $ls-05;
$line-height-h5:     $lh12;

/* H6 */
$color-h6:           $gray-500;
$font-family-h6:     $sans-serif;
$font-size-h6:       $fs-16;
$font-weight-h6:     $fw-regular;
$letter-spacing-h6:  $ls-05;
$line-height-h6:     $lh12;

