/*------------------
    Default
-------------------*/

/*------------ Font -----------*/

$nav-color:                     $gray-700;
$nav-font-family:               $sans-serif;
$nav-font-size:                 $fs-base;
$nav-font-weight:               $fw-semi-bold;
$nav-text-transform:            none;
$nav-letter-spacing:            0;
$nav-line-height:               $lh1;

/*------------ Spacers -----------*/

$nav-item-padding:              $spacer-10 0;
$nav-item-spacer:               $spacer-15;

/*------------ Hover -----------*/

$nav-hover-color:               $gray-900;

/*------------ Active -----------*/

$nav-active-color:              $alpha;




/*------------------
    Underlined
-------------------*/

/*------------ Nav Border Style -----------*/

$nav-border:                    3px solid $gray-200;

/*------------ Item Border Style -----------*/

$nav-item-border-width:         0 0 3px;
$nav-item-border-style:         solid;
$nav-item-border-color:         transparent;

/*------------ Hover -----------*/

$nav-item-hover-border-color:   $gray-400;

/*------------ Active -----------*/

$nav-item-active-border-color:  $alpha;




/*------------------
    Pills
-------------------*/

/*------------ Font -----------*/

$nav-pill-color:                $gray-700;
$nav-pill-font-family:          $sans-serif;
$nav-pill-font-size:            $fs-10;
$nav-pill-font-weight:          $fw-semi-bold;
$nav-pill-text-transform:       uppercase;

/*------------ Styling -----------*/

$nav-pill-border-width:         2px;
$nav-pill-border-style:         solid;
$nav-pill-border-radius:        50px;
$nav-pill-border-color:         transparent;

$nav-pill-background:           $white;
$nav-pill-box-shadow-width:     10px;
$nav-pill-box-shadow:           0 0 $nav-pill-box-shadow-width rgba($black, .1);

/*------------ Spacers -----------*/

$nav-pill-padding:              $spacer-10 $spacer-15;
$nav-pill-spacer:               $spacer-10;

/*------------ Hover -----------*/

$nav-pill-hover-background:     $white;
$nav-pill-hover-color:          $alpha;
$nav-pill-hover-border-color:   transparent;
$nav-pill-hover-box-shadow:     0 0 $nav-pill-box-shadow-width rgba($black, .2);

/*------------ Active -----------*/

$nav-pill-active-background:    $white;
$nav-pill-active-color:         $alpha;
$nav-pill-active-border-color:  $alpha;
$nav-pill-active-box-shadow:    0 0 $nav-pill-box-shadow-width rgba($black, .2);

