@mixin button($background, $color-from-background: 'true') {
  background-color: $background;

  @if ($color-from-background == 'true') {
    color: set-color-from-bg($background, darken($background, 60%), lighten($background, 60%));
  } @else {
    color: set-color-from-bg($background);
  }

  @include hover {
    background-color: set-color-from-bg($background, darken($background, 5%), lighten($background, 5%));

    &.is-transparent {
      background-color: $background;

      @if ($color-from-background == 'true') {
        color: set-color-from-bg($background, darken($background, 60%), lighten($background, 60%));
      } @else {
        color: set-color-from-bg($background);
      }
    }
  }

  &:active { box-shadow: 0 0 10px rgba(darken($background, 30%), .5); }

  &.is-outline {
    border-width: $button-border-width;
    border-style: solid;
    border-color: darken($background, 0);

    background-color: transparent;

    color: darken($background, 0);

    @include hover {
      border-color: $background;

      background-color: $background;

      @if ($color-from-background == 'true') {
        color: set-color-from-bg($background, darken($background, 60%), lighten($background, 60%));
      } @else {
        color: set-color-from-bg($background);
      }
    }
  }

  &.is-loading::before {
    background-color: set-color-from-bg($background, darken($background, 15%), lighten($background, 15%));
  }
}


@mixin button-gradient($background-1, $background-2, $color-from-background: 'true') {
  background-image: linear-gradient(135deg, lighten($background-1, 10%), darken($background-2, 10%));

  @if ($color-from-background == 'true') {
    color: set-color-from-bg($background-1, darken($background-1, 60%), lighten($background-1, 60%));
  } @else {
    color: set-color-from-bg($background-1);
  }

  &:active { box-shadow: 0 0 10px rgba(darken($black, 30%), .2); }

  &.is-outline { @include button-outline($background-1, $background-2, $color-from-background); }
}


@mixin button-outline($background-1, $background-2, $color-from-background: 'true') {
  @if ($color-from-background == 'true') {
    color: darken($background-1, 20%);
  } @else {
    color: set-color-from-bg(lighten($background-1, 40%));
  }

  z-index: 0;

  &::before,
  &::after {
    z-index: -1;
  }

  @include before {
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-image: linear-gradient(135deg, lighten($background-1, 10%), darken($background-2, 10%));
  }

  @include after {
    top: $button-border-width;
    left: $button-border-width;

    width: calc(100% - #{$button-border-width} * 2);
    height: calc(100% - #{$button-border-width} * 2);

    transition: 1s;
    @include cubicOut();

    background-color: $white;
  }

  @include hover {
    @if ($color-from-background == 'true') {
      color: set-color-from-bg($background-1, darken($background-1, 60%), lighten($background-1, 60%));
    } @else {
      color: set-color-from-bg($background-1);
    }

    &::after { opacity: 0; }
  }
}


