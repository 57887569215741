@import 'variables';
@import 'mixins';

/*******************************
        Main
*******************************/

.header {
  display: flex;

  position: fixed;
  top: 0;
  left: 0;

  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: $header-height;

  padding: $header-padding;

  background: linear-gradient($header-backgroud-color-1, $header-backgroud-color-2);

  z-index: 10;
}

.header-logo {
  width: $logo-width;
}

.header-nav {
  display: flex;
  align-items: center;

  .button + .button {
    margin-left: 5px;
  }
}

.header-nav-main {
  padding: $main-menu-padding;

  > li {
    &.is-active {
      > a {
        color: $main-menu-active-color;

        &::before {
          width: 100%;
        }
      }
    }

    > a {
      position: relative;

      color: $main-menu-color;
      font-size: $main-menu-font-size;
      font-weight: $main-menu-font-weight;
      text-transform: $main-menu-text-transform;

      @include before {
        right: 0;
        bottom: 0;

        width: 0;
        height: $main-menu-cursor-width;

        transition: .5s;

        background: linear-gradient(45deg, $main-menu-cursor-color-1, $main-menu-cursor-color-2);
      }

      @include hover {
        color: $main-menu-hover-color;

        &::before {
          right: auto;
          left: 0;
          width: 100%;
        }
      }
    }
  }

  ul {
    font-size: $sub-menu-font-size;
    font-weight: $sub-menu-font-weight;

    a {
      display: block;
      color: $sub-menu-color;
    }
  }
}

.header-nav-social {
  padding: $social-menu-padding;

  font-size: $social-menu-font-size;

  li {
    display: inline-block;
  }

  a {
    padding: $social-menu-item-padding;

    color: $social-menu-color;

    @include hover {
      color: $social-menu-hover-color;
    }
  }
}

.header-nav-lang {
  padding:  $language-menu-padding;
  font-size: $language-menu-font-size;
  font-weight: $language-menu-font-weight;

  li {
    display: inline-block;

    + li {
      &::before {
        margin-right: 3px;
        color: $language-menu-color;
        content: '/';
      }
    }

    &.is-active {
      a {
        color: $language-menu-active-color;
      }
    }
  }

  a {
    color: $language-menu-color;

    @include hover {
      color: $language-menu-hover-color;
    }
  }
}

/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {

}

@include breakpoint('tab') {
  .header {
    height: $header-height-tab;
    padding: $header-padding-tab;
  }

  .header-menu {
    display: flex;
    align-items: center;
  }

  .header-nav-main {
    padding: $main-menu-padding-tab;

    > li {
      display: inline-block;
      position: relative;
      padding: $main-menu-item-padding-tab;

      > a {
        padding: $main-menu-link-padding-tab;
      }

      > ul {
        top: 100%;
        left: 50%;
        padding-top: $sub-menu-spacer-top;

        transform: translate(-50%, $sub-menu-spacer-top);

        &::before {
          top: $sub-menu-spacer-top;
          height: calc(100% - #{$sub-menu-spacer-top});
        }
      }

      &:hover {
        > ul {
          transform: translateX(-50%);
        }
      }
    }

    ul {
      position: absolute;
      top: 0;
      left: 100%;

      width: 120px;

      transition: $sub-menu-transition-duration;
      @include cubicInOut;

      opacity: 0;
      visibility: hidden;

      z-index: 1;

      pointer-events: none;

      @include before {
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        border-radius: $sub-menu-border-radius;

        background-color: $sub-menu-background-color;

        box-shadow: $sub-menu-box-shadow;
      }

      li {
        position: relative;

        + li {
          border-top: $sub-menu-item-border-top;
        }

        &:hover {
          z-index: 1;
        }

        &.is-active {
          a {
            color: $sub-menu-active-color;
          }
        }
      }

      a {
        padding: $sub-menu-item-padding-tab;

        transition: $sub-menu-transition-duration;
        border-radius: $sub-menu-border-radius;

        background-color: $sub-menu-item-background-color-tab;

        &:hover {
          color: $sub-menu-hover-color;
          box-shadow: $sub-menu-item-hover-box-shadow;
        }
      }
    }

    li {
      &:hover {
        > ul {
          opacity: 1;
          visibility: visible;
          pointer-events: all;
        }
      }
    }

    .has-menu {
      > a {
        position: relative;
        padding-right: 20px;

        @include before {
          top: 50%;
          right: 10px;

          width: 7px;
          height: 7px;

          transform: rotate(45deg) translateY(-50%);
          transform-origin: 50% 0;

          border-width: $sub-menu-arrow-width $sub-menu-arrow-width 0 0;
          border-style: solid;
          border-color: $sub-menu-arrow-color;
        }
      }

      &:hover {
        a::before {
          border-color: $sub-menu-arrow-hover-color;
        }
      }
    }
  }

  .header-nav-lang {
    padding: $language-menu-padding-tab;
  }

  .header-menu-toggle {
    display: none;
  }
}

@include breakpoint('desk') {

}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {
  .header-menu {
    position: fixed;
    top: $header-height;
    left: 0;

    width: $mobile-menu-width;
    height: calc(100% - #{$header-height});

    transform: translateX(-100%);
    transition: .5s;
    @include quartIn();

    background-color: $mobile-menu-background-color;

    box-shadow: $mobile-menu-box-shadow;

    opacity: 0;
    visibility: hidden;

    z-index: 30;

    @include scrollbar-y;
  }

  .header-nav-main {
    li {
      border-top: $mobile-menu-item-border-top;

      li {
        background-color: rgba($black, .03);

        a {
          background-color: transparent;
        }

        > a {
          margin-left: 10px;
        }
      }
    }

    a {
      display: block;
      padding: 15px 20px;
    }
  }

  .header-nav-social,
  .header-nav-lang {
    border-top: $mobile-menu-item-border-top;

    text-align: center;
  }

  .has-menu-open {
    .header-menu {
      transform: none;
      opacity: 1;
      visibility: visible;
      @include quartOut();
    }
  }
}

@include breakpoint('mob', false) {

}
