// @import url('https://fonts.googleapis.com/css?family=Oswald|Source+Sans+Pro:300,400,600');

//////////////////////////////////////////
// SPACERS ///////////////////////////////
//////////////////////////////////////////
$spacer-5:               5px;
$spacer-10:              10px;
$spacer-15:              15px;
$spacer-20:              20px;
$spacer-25:              25px;
$spacer-30:              30px;
$spacer-40:              40px;
$spacer-50:              50px;
$spacer-75:              75px;
$spacer-100:             100px;
$spacer-150:             150px;

//////////////////////////////////////////
// COLORS ////////////////////////////////
//////////////////////////////////////////

/*********** Grays ************/

$gray-100: #EEF0FA;
$gray-200: #91BBD5;
$gray-300: #cccccc !default;
$gray-400: #bbbbbb !default;
$gray-500: #9a9a9a !default;
$gray-600: #777777 !default;
$gray-700: #555555 !default;
$gray-800: #333333 !default;
$gray-900: #111111 !default;

$grays: () !default;
$grays: map-merge((
  'gray-100': $gray-100,
  // 'gray-200': $gray-200,
  'gray-300': $gray-300,
  // 'gray-400': $gray-400,
  'gray-500': $gray-500,
  // 'gray-600': $gray-600,
  'gray-700': $gray-700,
  // 'gray-800': $gray-800,
  'gray-900': $gray-900
), $grays);

$white:                   #FFF;
$black:                   #000;


/*********** Neutrals ************/

$white:                   #FFF;
$black:                   #000;

$neutrals: () !default;
$neutrals: map-merge((
  'white':    $white,
  'black':    $black,
), $neutrals);


/*********** Colors ************/

$alpha:                   #074885;
$beta:                    #2A89C5;
$gamma:                   $gray-200;
$delta:                   #6CD2D4;
$epsilon:                 #3399cc;

$rd:                      #EF7D00;
$gd:                      $alpha;
$gc:                      #FFE600;

$success:                 #5cb85c;
$info:                    #5bc0de;
$warning:                 #f0ad4e;
$danger:                  #d9534f;

$border-color:            $gray-100;
$link-color:              $alpha;

$colors: () !default;
$colors: map-merge((
  'alpha': $alpha,
  'beta': $beta,
  'gamma': $gamma,
  'delta': $delta,
  // 'success': $success,
  // 'info': $info,
  // 'warning': $warning,
  // 'danger': $danger,
), $colors);

$infos: () !default;
$infos: map-merge((
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
), $infos);


//////////////////////////////////////////
// TYPOGRAPHY ////////////////////////////
//////////////////////////////////////////
$sans-serif:              'Inter', sans-serif;
$serif:                   serif;
$mono:                    'Oswald', Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
$font-family-base:        $sans-serif;

/*********** Font-Sizes ************/

$fs-10:             10px;
$fs-11:             11px;
$fs-12:             12px;
$fs-13:             13px;
$fs-14:             14px;
$fs-15:             15px;
$fs-16:             16px;
$fs-18:             18px;
$fs-20:             20px;
$fs-22:             22px;
$fs-30:             30px;
$fs-40:             40px;
$fs-50:             50px;
$fs-base:           $fs-14;


/*********** Font-Weights ************/

$fw-thin:           100;
$fw-extra-light:    200;
$fw-light:          300;
$fw-regular:        400;
$fw-medium:         500;
$fw-semi-bold:      600;
$fw-bold:           700;
$fw-extra-bold:     800;
$fw-black:          900;
$fw-base:           $fw-regular;


/*********** Letter-Spacing ************/

$ls-01:             .01rem;
$ls-02:             .02rem;
$ls-05:             .05rem;
$ls-1:              .1rem;
$ls-base:           0;


/*********** Line-Heights ************/

$lh1:               1;
$lh12:              1.2;
$lh14:              1.4;
$lh16:              1.6;
$lh18:              1.8;
$lh2:               2;
$lh-base:           $lh12;


//////////////////////////////////////////
// BODY //////////////////////////////////
//////////////////////////////////////////
$body-bg:                 $gray-100;
$text-color:              $alpha;


//////////////////////////////////////////
// BREAKPOINTS ///////////////////////////
//////////////////////////////////////////

$breakpoints: (
  'base': 0,
  'mob': 600px,
  'tab': 900px,
  'desk': 1400px,
  'wide': 1800px
);
