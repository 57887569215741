$input-bg:                $white;
$input-color:             $alpha;
$input-fw:                $fw-regular;
$input-focus-bg:          $white;
$input-border:            $gamma;
$input-focus-border:      $alpha;
$input-placeholder:       $beta;
$input-border-style:      solid;
$input-border-width:      1px;
$input-font-size:         $fs-base;

$input-padding-x:         $spacer-20;
$input-padding-y:         $spacer-20;
$input-height:            43px;

$input-lg-padding-x:      0;
$input-lg-padding-y:      .8rem;
$input-lg-height:         50px;

/*********** Inverse Variant ************/
$input-color-inverse:       $white;
// $input-border-inverse:      $border;
$input-placeholder-inverse: $gray-400;

/*********** Input Type File ************/
$input-file-bg:           $gray-400;
$input-file-color:        $white;

$input-file-empty-color:  $white;
$input-file-empty-bg:     $gray-400;

/*********** Dropdowns ************/
$caret-size:              11px;
$dropdown-box-shadow:     0 2px 3px rgba($black, 0.2);
$dropdown-color:          $gray-400;
$dropdown-bg:             $white;
$dropdown-max-height:     200px;
$dropdown-padding-v:      $spacer-10/2;
$dropdown-fw:             $fw-regular;
// $dropdown-font-size:      $fs-12;

// $dropdown-hover-bg:       $blue;
$dropdown-hover-color:    $white;

/*********** Label ************/
$label-color:             $black;
$label-color-inverse:     $white;

/*********** Custom Checkbox ************/
$checkradio-bg:           $white;
// $checkradio-border:       $border;

$checkradio-hover-bg:     $white;
// $checkradio-hover-border: $yellow;
