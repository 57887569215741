$padding:             $spacer-20;
$border-width:        3px;
$border-radius:       10px;
$background-color:    $white;
$transition-duration: .5s;

$font-size:       $fs-12;
$font-weight:     $fw-semi-bold;
$letter-spacing:  0;
$line-height:     $lh14;
