.autocomplete {
  position: relative;

  z-index: 2;

  ul {
    position: absolute;
    top: 100%;
    left: 0;

    width: 100%;
    max-height: 230px;

    border-radius: 10px;
    background-color: $white;

    box-shadow: 0 0 30px rgba($black, .2);

    @include scrollbar-y($gray-100, $gray-100, transparent);

    z-index: 1;

    li {
      padding: 13px 20px;

      @include hover {
        background-color: $gray-100;
      }

      &:focus {
        background-color: $gray-100;
      }

      + li {
        border-top: 1px solid $gray-100;
      }
    }
  }

  .button {
    position: absolute;
    top: 50%;
    right: 8px;

    transform: translateY(-50%);
  }
}
