@import 'variables';
@import 'mixins';

/*******************************
        Main
*******************************/

.has-shadow {
  @for $i from 1 through $shadow-indexes {
    &-#{$i} {
      box-shadow: ($shadow-x * $i) ($shadow-y * $i) ($shadow-width * $i) $shadow-color;
    }
  }
}

.has-hover-shadow {
  @for $i from 1 through $shadow-indexes {
    &-#{$i} {
      @include hover {
        box-shadow: ($shadow-x * $i) ($shadow-y * $i) ($shadow-width * $i) $shadow-color;
      }
    }
  }
}

/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {

}

@include breakpoint('tab') {

}

@include breakpoint('desk') {

}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {

}
