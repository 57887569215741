/*******************************
      Padding & Heights
*******************************/

$badge-padding-v:           5px;
$badge-padding-h:           10px;

$badge-border-radius:       2px;


/*------------------
    Fonts
-------------------*/
$badge-font-weight:         $fw-semi-bold;
$badge-letter-spacing:      $ls-02;
$badge-text-transform:      uppercase;

/*------------------
    Outline
-------------------*/
$badge-border-width:        2px;
$badge-border-style:        solid;

/*******************************
             Mappings
*******************************/

$badges-colors: (
  'alpha': $alpha,
  'beta': $beta,
  'gray-500': $gray-500,
  'gray-700': $gray-700
);
