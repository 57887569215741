.has-backround-cover {
  background-position: center;
  background-size: cover;
}

@each $key, $value in $neutrals {
	.has-background-#{$key} {
		background-color: $value;
	}
}

@each $key, $value in $grays {
	.has-background-#{$key} {
		background-color: $value;
	}
}

@each $key, $value in $colors {
	.has-background-#{$key} {
		background-color: $value;
	}
}
