@mixin before {
  &::before {
    display: block;
    position: absolute;

    content: '';

    @content;
  }
}

@mixin after {
  &::after {
    display: block;
    position: absolute;

    content: '';

    @content;
  }
}
