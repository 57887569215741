.logo {
  display: flex;
  align-items: center;

  p {
    margin-top: -7px;
    font-size: $fs-40;
    font-weight: $fw-light;
  }
}
