@import 'variables';
@import 'mixins';

.group {
  /*******************************
        Base
  *******************************/

  /* Box */
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > * + * {
    margin-left: $spacer;
  }

  &.is-merged {
    > * {
      .button,
      &.button {
        border-radius: 0;
      }

      &.input {
        flex: 1;
      }

      &:first-child {
        .button,
        &.button {
          @include border-left-radius($button-border-radius);

          &.is-rounded {
            @include border-left-radius($button-rounded-border-radius);
          }
        }
      }

      &:last-child {
        .button,
        &.button {
          @include border-right-radius($button-border-radius);

          &.is-rounded {
            @include border-right-radius($button-rounded-border-radius);
          }
        }
      }

      + * {
        margin-left: $merged-spacer;
      }
    }
  }

  &.is-vertical {
    flex-direction: column;
    align-items: flex-start;

    > * + * {
      margin-top: $spacer;
      margin-left: 0;
    }

    &.is-merged {
      > * {
        &:first-child {
          .button,
          &.button {
            @include border-left-radius(0);
            @include border-top-radius($button-border-radius);
          }
        }

        &:last-child {
          .button,
          &.button {
            @include border-right-radius(0);
            @include border-bottom-radius($button-border-radius);
          }
        }

        + * {
          margin-top: $merged-spacer;
        }
      }
    }
  }
}

/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {

}

@include breakpoint('tab') {

}

@include breakpoint('desk') {

}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {

}

