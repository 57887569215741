.map {
  display: flex;
  height: calc(100vh - #{$header-height});

  > * {
    flex: 1;
    height: 100%;
  }
}

.map-filters {
  label {
    font-size: $fs-20;
  }
}

.map-filters-heading {
  display: flex;
  justify-content: space-between;

  .button {
    padding: 2px 5px;

    font-size: $fs-10;
    font-weight: $fw-semi-bold;
    text-transform: uppercase;
  }
}

.map-nav {
  position: relative;
  max-width: 360px;
  background-color: $gray-100;
  z-index: 1;

  @extend .has-shadow-2;
  @include scrollbar-y($gray-100, $gray-100, transparent);
}

.map-nav-section {
  @include padding-h($spacer-30);

  > div {
    @include padding-v($spacer-20);
  }

  ul {
    margin-top: $spacer-10;

    .button {
      display: inline-flex;
      align-items: center;
      margin-left: -$spacer-10;
      color: $beta;

      .icon {
        font-size: $fs-20;
      }

      @include hover {
        background-color: $white;
        color: $alpha;
      }

      &.is-active {
        background-color: $white;
        color: $alpha;
        box-shadow: 0 0 10px rgba($black, .2);
        z-index: 1;
      }
    }
  }

  + .map-nav-section {
    > div {
      border-top: 2px solid $white;
    }
  }
}

.map-container {
  position: relative;

  padding: $spacer-30;

  background-color: $gray-200;

  overflow: hidden;

  .card {
    display: inline-block;
    position: relative;

    border-radius: 10px;

    background-color: rgba($white, .8);
  }

  .card-content {
    padding: $spacer-15 $spacer-20;
  }

  .chips-list {
    margin: 0;
    padding: 0;
    border: 0;
  }

  .button-close {
    position: absolute;
    top: 0;
    right: 50px;

    transform: translateY(-50%);
  }
}

.map-loader {
  float: right;

  transition: .3s;
  border: 0;

  opacity: 0;


  &.is-loading {
    opacity: 1;
  }
}

#map {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  .cluster {
    > div {
      top: 50% !important;
      left: 50% !important;

      width: 37px !important;
      height: 37px;

      transform: translate(-50%, -50%);
      transition: .3s;

      border: 2px solid $white;
      border-radius: 10px;
      background-color: lighten($gray-300, 5);

      color: $alpha !important;
      font-family: $sans-serif;
      line-height: 35px !important;

      box-shadow: 0 0 10px rgba($black, .1);

      @include hover {
        box-shadow: 0 0 5px rgba($black, .3);
      }
    }

    img {
      display: none;
    }
  }
}

.marker {
  position: absolute;

  width: 23px;
  height: 23px;

  color: $alpha;
  font-family: $sans-serif;
  font-size: $fs-base;

  > div {
    width: 100%;
    height: 100%;

    transform: scale(.9);

    border: 2px solid $white;
    border-radius: 50%;

    background-color: $white;

    box-shadow: 0 2px 6px rgba($black, .15);

    svg {
      width: 100%;
      height: 100%;
    }

    &.is {
      &-RD { background-color: $rd; }
      &-GD { background-color: $gd; }
      &-GC { background-color: $gc; }
    }
  }

  > span {
    display: block;

    position: absolute;
    bottom: calc(100% - 3px);
    left: 50%;

    padding: 6px 11px;

    transform: translate(-50%, 10px);

    border-radius: 20px;

    background-color: $white;

    white-space: nowrap;

    opacity: 0;
    visibility: hidden;

    z-index: -1;

    pointer-events: none;
  }

  > span,
  > div {
    transition: .4s;
    @include InOutBack;
  }

  @include hover {
    z-index: 1;

    > span {
      transform: translate(-50%, 0);
      opacity: 1;
      visibility: visible;
    }

    > div {
      transform: none;
      box-shadow: 0 5px 15px rgba($black, .25);
    }
  }
}

.map-scale-control {
  position: absolute;
  right: 0;
  bottom: 40%;

  padding: 8px;

  background-color: $white;

  @include border-left-radius(10px);

  z-index: 1;
}

.map-description {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;

  transform: translateY(100%);
  transition: .3s;
  @include quartInOut;

  background-color: rgba($alpha, .73);

  color: $white;

  opacity: 0;

  > div {
    padding: 25px 30px;
  }

  .row {
    flex: 1;
    margin: 0;
  }

  .title-h5 {
    strong {
      font-weight: $fw-semi-bold;
    }

    + * {
      margin-top: 20px;
    }
  }

  &.is-visible {
    transform: none;
    opacity: 1;
  }
}

.map-description-chart {
  display: inline-block;

  width: 25px;
  height: 25px;

  margin-top: -6px;

  border: 2px solid $white;
  border-radius: 50%;

  overflow: hidden;

  vertical-align: middle;

  svg {
    display: block;

    position: relative;
    top: -1px;
    left: -1px;
  }
}

.map-description-details {
  display: flex;

  .row {
    > * {
      border-left: 1px solid $gray-200;
    }
  }

  > * + * {
    margin-left: 20px !important;
  }

  .has-icon {
    display: inline-flex;
    align-items: center;

    font-weight: $fw-semi-bold;

    .icon {
      margin-right: 5px;
      font-size: $fs-20;
    }
  }

  &.is-simple {
    align-items: center;
  }

  table {
    td {
      padding: 3px 20px 5px 0;

      &:last-child {
        font-weight: $fw-semi-bold;
      }
    }
  }
}

.map-description-heading {
  background-color: $alpha;

  .title-h4 {
    color: $gray-200;
  }

  > * + * {
    margin-top: 10px;
  }
}

.year-toggler {
  display: inline-flex;

  margin: auto;
  padding: 5px;

  border-radius: 50px;

  background-color: $white;

  color: $alpha;
  font-weight: $fw-semi-bold;

  label {
    @include margin-h(10px);
  }

  .button {
    span {
      display: block;

      @include before {
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
        @include triangle-right(5px, 6px, $white);
      }

      &.is-left {
        transform: rotate(180deg);
      }
    }
  }
}

[class*='chip-'] {
  display: inline-block;
  width: 12px;
  height: 12px;

  margin-top: -2px;
  margin-right: 10px;

  border: 1px solid $white;
  border-radius: 10px;

  // background-color: red;

  vertical-align: middle;
}

.chip {
  &-RD { background-color: $rd; }
  &-GD { background-color: $gd; }
  &-GC { background-color: $gc; }
  &-NC { background-color: $gray-100; }
}

[v-cloak] { display: none; }


/*******************************
        Breakpoints Up
*******************************/

@include breakpoint('mob') {

}

@include breakpoint('tab') {

}

@include breakpoint('desk') {
  .map {
    height: calc(100vh - #{$header-height-tab});
  }
}


/*******************************
        Breakpoints Down
*******************************/

@include breakpoint('desk', false) {

}

@include breakpoint('tab', false) {

}

@include breakpoint('mob', false) {

}

