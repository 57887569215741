$card-padding:                   $spacer-25;
$card-padding-inset:             $spacer-10;
$card-border-radius:             0;

$card-color-title:               $gray-900;
$card-color-title-inverse:       $gray-100;
$card-color-title-thumbnail:     $gray-100;
$card-font-family-title:         $sans-serif;
$card-font-size-title:           $fs-20;
$card-font-weight-title:         $fw-semi-bold;
$card-letter-spacing-title:      0;
$card-line-height-title:         $lh-base;
$card-text-transform-title:      none;

$card-color-subtitle:            $gray-500;
$card-color-subtitle-inverse:    $gray-300;
$card-color-subtitle-thumbnail:  $gray-300;
$card-font-family-subtitle:      $sans-serif;
$card-font-size-subtitle:        $fs-13;
$card-font-weight-subtitle:      $fw-medium;
$card-letter-spacing-subtitle:   0;
$card-line-height-subtitle:      $lh-base;
$card-text-transform-subtitle:   uppercase;

$card-color:                     $gray-700;
$card-color-inverse:             $gray-100;
$card-color-thumbnail:           $gray-100;
$card-font-family:               $sans-serif;
$card-font-size:                 $fs-base;
$card-font-weight:               $fw-base;
$card-letter-spacing:            0;
$card-line-height:               $lh-base;
$card-text-transform:            none;

$card-title-spacer:              $spacer-15;

$card-transform:                 scale(1.05);

$card-content-separator:         1px solid $border-color;
